import React, { useEffect, useState } from "react";
import axios from "axios";
import "../context/Css.css";
import { Helmet } from "react-helmet-async";
import SubPagePnMain from "./SubPagePnMain";
import SubPagePnProduct from "./SubPagePnProduct";
import SubPagePnPrice from "./SubPagePnPrice";
import SubPagePnResource from "./SubPagePnResource";
import SubPagePnMainEN from "./SubPagePnMainEN";
import SubPagePnProductEN from "./SubPagePnProductEN";
import SubPagePnPriceEN from "./SubPagePnPriceEN";
import SubPagePnResourceEN from "./SubPagePnResourceEN";

export default function MainPagePn() {
  const [showContentPn, setShowContentPn] = useState([1, 0, 0, 0]);
  const [showFooterPn, setShowFooterPn] = useState([0, 0, 0, 0]);
  const [zhLanguage, setZhLanguage] = useState(true);

  const [showInquiry, setShowInquiry] = useState(false);
  const [inquiryPhone, setInquirePhone] = useState("");
  const [inquiryName, setInquireName] = useState("");
  const [inquiryText, setInquireText] = useState("");
  const [inquiryHeaderText, setInquireHeaderText] = useState("感谢您对项日葵的关注！请留下您的联系方式和问题：");

  const showMain = () => {
    setShowContentPn([1, 0, 0, 0]);
    setShowFooterPn([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const showProduct = () => {
    setShowContentPn([0, 1, 0, 0]);
    setShowFooterPn([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const showPrice = () => {
    setShowContentPn([0, 0, 1, 0]);
    setShowFooterPn([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const showResource = () => {
    setShowContentPn([0, 0, 0, 1]);
    setShowFooterPn([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const shiftLanguage = () => {
    setZhLanguage((zhLanguage) => !zhLanguage);
  };

  useEffect(() => {
    if (showFooterPn.find((el) => el === 1)) {
      const elmnt = document.getElementById(`footer${showFooterPn.findIndex((el) => el === 1)}`);
      window.scrollBy(0, elmnt.offsetHeight);
    }
  }, [showFooterPn]);

  const showReccommend = () => setShowFooterPn([1, 0, 0, 0]);
  const showService = () => setShowFooterPn([0, 1, 0, 0]);
  const showAgreement = () => setShowFooterPn([0, 0, 1, 0]);
  const showAboutUs = () => setShowFooterPn([0, 0, 0, 1]);
  const closeFooter = () => {
    if (showFooterPn.find((el) => el === 1)) {
      const elmnt = document.getElementById(`footer${showFooterPn.findIndex((el) => el === 1)}`);
      window.scrollBy(0, -elmnt.offsetHeight);
    }
    setShowFooterPn([0, 0, 0, 0]);
  };

  const sendInquiry = async () => {
    if (!/^[1][0-9]{10}$/.test(inquiryPhone)) {
      setInquireHeaderText(zhLanguage ? "请输入正确的手机号..." : "Incorrect phone number...");
      return;
    }
    if (!inquiryName) {
      setInquireHeaderText(zhLanguage ? "请输入您的称谓..." : "Please input your name...");
      return;
    }
    if (!inquiryText) {
      setInquireHeaderText(zhLanguage ? "请输入您的问题描述..." : "Please describe your inquiry in text...");
      return;
    }

    const controller = new AbortController();
    const timeoutAxios = setTimeout(() => controller.abort(), 8000);

    const dataInput = {
      phoneNumber: inquiryPhone,
      name: inquiryName.trim(),
      description: inquiryText.trim(),
    };

    try {
      const responseInfo = await axios.post("https://api.yige.world:9443/public/newTicket", dataInput, {
        signal: controller.signal,
      });

      if (responseInfo.data.suc) {
        setInquireHeaderText(
          zhLanguage
            ? "您的问题已成功发送，客服将尽快联系您！"
            : "Your inquiry has been sent successfully! sf2Pro Helpdesk would contact you soon..."
        );

        setTimeout(() => {
          setShowInquiry(false);
          setInquirePhone("");
          setInquireName("");
          setInquireText("");
          setInquireHeaderText(
            zhLanguage
              ? "感谢您对项日葵的关注！请留下您的联系方式和问题，客服将尽快处理并第一时间答复您："
              : "Thanks for your regards! Please input your details and sf2Pro Helpdesk would contact you soon:"
          );
        }, 2000);
      } else {
        setInquireHeaderText(
          zhLanguage
            ? "抱歉，服务器处理异常，烦请邮件给 support@yige.world，多谢！"
            : "Sorry, server no responding...please mail to support@yige.world, thanks!"
        );
      }
    } catch (error) {
      setInquireHeaderText(
        zhLanguage
          ? "抱歉，发送遇到问题，烦请邮件给 support@yige.world，多谢！"
          : "Sorry, sending message failed...please mail to support@yige.world, thanks!"
      );
    } finally {
      clearTimeout(timeoutAxios);
    }
  };

  const startInquiry = () => {
    window.scrollTo(0, 0);
    setInquireHeaderText(
      zhLanguage
        ? "感谢您对项日葵的关注！请留下您的联系方式和问题："
        : "Please input your details and sf2Pro Helpdesk would contact you soon:"
    );
    setShowInquiry(true);
  };

  const endInquiry = () => {
    setInquirePhone("");
    setInquireName("");
    setInquireText("");
    setInquireHeaderText(
      zhLanguage
        ? "感谢您对项日葵的关注！请留下您的联系方式和问题："
        : "Please input your details and sf2Pro Helpdesk would contact you soon:"
    );
    setShowInquiry(false);
  };

  return (
    <div className="phoneHome">
      <Helmet>
        <title>项日葵</title>
        <meta name="description" content="供需双方的协同平台，专属服务团队的工作空间" />
        <meta name="keywords" content="项日葵,服务管理,目标管理,屹歌" />
      </Helmet>
      {zhLanguage && (
        <div className="pnHeader">
          <div className="pnHeaderLine1">
            <img
              className="iconLarge"
              style={{ marginLeft: 16 }}
              src={require("../assets/logoSFwText.png")}
              alt="项日葵"
              onClick={showMain}
            ></img>

            <img
              className="iconMiddle"
              style={{ marginRight: 16 }}
              src={require("../assets/chat.png")}
              alt="用户问询"
              onClick={startInquiry}
            ></img>
          </div>
          <div className="pnHeaderLine2">
            <button className="btnHeaderLine2Pn" onClick={showMain}>
              首页
            </button>
            <button className="btnHeaderLine2Pn" onClick={showProduct}>
              产品
            </button>
            <button className="btnHeaderLine2Pn" onClick={showPrice}>
              价格
            </button>
            <button className="btnHeaderLine2Pn" onClick={showResource}>
              资源
            </button>
            <button className="shiftLangPn" onClick={shiftLanguage}>
              EN
            </button>
          </div>
        </div>
      )}

      {!zhLanguage && (
        <div className="pnHeader">
          <div className="pnHeaderLine1">
            <img
              className="iconLarge"
              style={{ marginLeft: 16 }}
              src={require("../assets/logoSFwTextEN.png")}
              alt="sf2Pro"
              onClick={showMain}
            ></img>
            <img
              className="iconMiddle"
              style={{ marginRight: 16 }}
              src={require("../assets/chat.png")}
              alt="Inquiry"
              onClick={startInquiry}
            ></img>
          </div>
          <div className="pnHeaderLine2">
            <button className="btnHeaderLine2Pn" onClick={showMain}>
              Home
            </button>
            <button className="btnHeaderLine2Pn" onClick={showProduct}>
              Product
            </button>
            <button className="btnHeaderLine2Pn" onClick={showPrice}>
              Price
            </button>
            <button className="btnHeaderLine2Pn" onClick={showResource}>
              Resource
            </button>
            <button className="shiftLangPn" onClick={shiftLanguage}>
              ZH
            </button>
          </div>
        </div>
      )}

      {showContentPn[0] === 1 && zhLanguage && (
        <SubPagePnMain goProduct={showProduct} goResource={showResource}></SubPagePnMain>
      )}
      {showContentPn[1] === 1 && zhLanguage && <SubPagePnProduct goResource={showResource}></SubPagePnProduct>}
      {showContentPn[2] === 1 && zhLanguage && <SubPagePnPrice goResource={showResource}></SubPagePnPrice>}
      {showContentPn[3] === 1 && zhLanguage && <SubPagePnResource></SubPagePnResource>}
      {showContentPn[0] === 1 && !zhLanguage && (
        <SubPagePnMainEN goProduct={showProduct} goResource={showResource}></SubPagePnMainEN>
      )}
      {showContentPn[1] === 1 && !zhLanguage && <SubPagePnProductEN goResource={showResource}></SubPagePnProductEN>}
      {showContentPn[2] === 1 && !zhLanguage && <SubPagePnPriceEN goResource={showResource}></SubPagePnPriceEN>}
      {showContentPn[3] === 1 && !zhLanguage && <SubPagePnResourceEN></SubPagePnResourceEN>}

      {showFooterPn[0] === 1 && zhLanguage && (
        <div className="footerWrapperPn" id="footer0">
          <p className="footerText1Pn">感谢您对项日葵的推荐！请复制以下链接发给您的朋友（手机端请长按）</p>
          <div className="pnDescMain">
            <p className="secSubTitleSpn">网页端用户注册</p>
            <p className="directLink">https://sf.yige.world/register</p>
          </div>
          <div className="pnDescMain">
            <p className="secSubTitleSpn">移动端APP下载</p>
            <p className="directLink">https://sf.yige.world/</p>
          </div>
          <div className="pnDescMain">
            <br />
          </div>
          <img className="closeFooterShowPn" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
        </div>
      )}
      {showFooterPn[0] === 1 && !zhLanguage && (
        <div className="footerWrapperPn" id="footer0">
          <p className="footerText1Pn">
            Appreciate your recommendation! Please copy below link and send it to your friends
          </p>
          <div className="pnDescMain">
            <p className="secSubTitleSpn">Web Register</p>
            <p className="directLink">https://sf.yige.world/register</p>
          </div>
          <div className="pnDescMain">
            <p className="secSubTitleSpn">APP Download</p>
            <p className="directLink">https://sf.yige.world/</p>
          </div>
          <div className="pnDescMain">
            <br />
          </div>
          <img
            className="closeFooterShowPn"
            src={require("../assets/undo.png")}
            alt="Cancel"
            onClick={closeFooter}
          ></img>
        </div>
      )}
      {showFooterPn[1] === 1 && zhLanguage && (
        <div className="footerWrapperPn" id="footer1">
          <p className="footerText1Pn">在项日葵中，您可通过以下三种方式获取服务</p>
          <div className="footerServicePn">
            <div className="footerServiceTypePn">
              <h3 className="secTitleSpn">自助服务</h3>
              <h3 className="secDescriptionScenter">免费</h3>
              <p className="secDescriptionScenter">
                用户可在项日葵中
                <br />
                搜索帮助文档
                <br />
                也可以在本网页中
                <br />
                搜索技术说明
                <br />
                搜索常见问题
              </p>
            </div>
            <div className="footerServiceTypePn">
              <h3 className="secTitleSpn">项日葵客服</h3>
              <h3 className="secDescriptionScenter">免费</h3>
              <p className="secDescriptionScenter">
                用户可在项日葵中
                <br />
                创建用户反馈
                <br />
                也可点击右下角的
                <br />
                项日葵在线客服
              </p>
            </div>
            <div className="footerServiceTypePn">
              <h3 className="secTitleSpn">增值服务</h3>
              <h3 className="secDescriptionScenter">收费</h3>
              <p className="secDescriptionScenter">
                如果团队需要定制
                <br />
                系统（比如自定义
                <br />
                业务指标）请联系
                <br />
                在线客服
              </p>
            </div>
          </div>
          <img className="closeFooterShowPn" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
        </div>
      )}
      {showFooterPn[1] === 1 && !zhLanguage && (
        <div className="footerWrapperPn" id="footer1">
          <p className="footerText1Pn">Get sf2Pro support by following ways</p>
          <div className="footerServicePn">
            <div className="footerServiceTypePn">
              <h3 className="secTitleSpn">Self-Service</h3>
              <h3 className="secDescriptionScenter">Free</h3>
              <p className="secDescriptionScenter">
                <br />
                Online Help
                <br />
                Specification
                <br />
                FAQ
              </p>
            </div>
            <div className="footerServiceTypePn">
              <h3 className="secTitleSpn">sf2Pro Helpdesk</h3>
              <h3 className="secDescriptionScenter">Free</h3>
              <p className="secDescriptionScenter">
                <br />
                Service Ticket
                <br />
                sf2Pro Online
                <br />
                &nbsp;
              </p>
            </div>
            <div className="footerServiceTypePn">
              <h3 className="secTitleSpn">Value-added Service</h3>
              <h3 className="secDescriptionScenter">With a Fee</h3>
              <p className="secDescriptionScenter">
                <br />
                Customize system
                <br />
                VIP Support
                <br />
                &nbsp;
              </p>
            </div>
          </div>
          <img
            className="closeFooterShowPn"
            src={require("../assets/undo.png")}
            alt="Cancel"
            onClick={closeFooter}
          ></img>
        </div>
      )}
      {showFooterPn[2] === 1 && zhLanguage && (
        <div className="footerWrapperPn" id="footer2">
          <p className="footerText1Pn">请耐心阅读以下文档，如有疑问可随时联系客服</p>
          <p>
            <br />
            <br />
            <br />
          </p>
          <div style={{ display: "flex", flexDirection: "column", height: 64, justifyContent: "space-between" }}>
            <a
              className="pnDirectInline"
              href={
                "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerAgreementTermOfService.pdf"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              项日葵用户服务协议
            </a>
            <a
              className="pnDirectInline"
              href={
                "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerAgreementPrivacyProtection.pdf"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              项日葵隐私保护协议
            </a>
          </div>
          <p>
            <br />
            <br />
            <br />
            <br />
          </p>
          <img className="closeFooterShowPn" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
        </div>
      )}
      {showFooterPn[2] === 1 && !zhLanguage && (
        <div className="footerWrapperPn" id="footer2">
          <p className="footerText1Pn">Please read below documents carefully or call sf2Pro for any questions</p>
          <p>
            <br />
            <br />
            <br />
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 64,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a
              className="pnDirectInline"
              href={
                "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerAgreementTermOfServiceEN.pdf"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              User Service Agreement
            </a>
            <a
              className="pnDirectInline"
              href={
                "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerAgreementPrivacyProtectionEN.pdf"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              Privacy Protection Agreement
            </a>
          </div>
          <p>
            <br />
            <br />
            <br />
            <br />
          </p>
          <img className="closeFooterShowPn" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
        </div>
      )}
      {showFooterPn[3] === 1 && zhLanguage && (
        <div className="footerWrapperPn" id="footer3">
          <img className="logoSFwText" src={require("../assets/yigeLogoFull.png")} alt="屹歌"></img>
          <br />

          <p className="footerText2Pn">
            屹歌是由专业人士建立的初创公司，屹歌的业务聚焦企业或团队的数字化管理。屹歌合伙人的平均数字化经验为23年，所曾供职的公司均为世界一流数字化企业，像IBM、埃森哲、普华永道等。
            <br />
            <br />
            屹歌人基于高科技领域的服务实践，打造了这款适用于服务团队的项日葵系统，其产品设计也真正把握了服务行业的痛点和难点。屹歌人认为在一眼可见的未来，现代服务业必将成为经济发展的支柱，屹歌人将与您一起迎接这个机遇和挑战！
            <br />
            <br />
          </p>

          <p className="footerText3Pn">
            <br />
            &nbsp;&nbsp;support@yige.world
            <br />
            上海屹歌信息技术有限公司
          </p>
          <br />
          <img className="closeFooterShowPn" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
        </div>
      )}

      {showFooterPn[3] === 1 && !zhLanguage && (
        <div className="footerWrapperPn" id="footer3">
          <img className="logoSFwText" src={require("../assets/yigeLogoFull.png")} alt="Yige"></img>
          <br />

          <p className="footerText2Pn">
            Yige is a startup company founded by professionals focusing on developing a product for team management.
            Yige founders have 20+ years of experience in top digital companies like IBM, Accenture and PWC.
            <br />
            <br />
            Yige team has developed sf2Pro based on service practices in high-tech consulting industries. sfPro’s designs
            therefore, have well captured the pain points and core values in the service industry. Yige sincerely believes
            the modern service industry will be the engine in the future economy. We are ready to work with you to
            embrace that future.
            <br />
            <br />
          </p>

          <p className="footerText3Pn">
            <br />
            &nbsp;&nbsp;support@yige.world
            <br />
            Yige Information Technology
          </p>
          <br />
          <img
            className="closeFooterShowPn"
            src={require("../assets/undo.png")}
            alt="Cancel"
            onClick={closeFooter}
          ></img>
        </div>
      )}

      {zhLanguage && (
        <div className="pnFooter">
          <div className="footerButtonsPn">
            <button className="btnPnFooter" onClick={showReccommend}>
              推荐
            </button>
            <button className="btnPnFooter" onClick={showService}>
              服务
            </button>
            <button className="btnPnFooter" onClick={showAgreement}>
              协议
            </button>
            <button className="btnPnFooter" onClick={showAboutUs}>
              关于
            </button>
          </div>
          <a
            href="https://beian.miit.gov.cn/"
            target={"_blank"}
            rel="external noreferrer"
            style={{ textDecoration: "none" }}
          >
            <p className="textPnFooter">Copyright © 2021-2024 · 沪ICP备2022003574号-2</p>
          </a>
        </div>
      )}

      {!zhLanguage && (
        <div className="pnFooter">
          <div className="footerButtonsPn">
            <button className="btnPnFooter" onClick={showReccommend}>
              Share
            </button>
            <button className="btnPnFooter" onClick={showService}>
              Support
            </button>
            <button className="btnPnFooter" onClick={showAgreement}>
              Agreement
            </button>
            <button className="btnPnFooter" onClick={showAboutUs}>
              About
            </button>
          </div>
          <a
            href="https://beian.miit.gov.cn/"
            target={"_blank"}
            rel="external noreferrer"
            style={{ textDecoration: "none" }}
          >
            <p className="textPnFooter">Copyright © 2021-2024 · ICP 2022003574-2</p>
          </a>
        </div>
      )}

      {showInquiry && zhLanguage && (
        <div className="pnInquiryBackground">
          <div className="pnInquiryForm">
            <div className="pnInquiryInputBoxHeaderText">
              <p className="pnInquiryHeaderText">{inquiryHeaderText}</p>
            </div>
            <div className="pnInquiryInputBox">
              <h3 className="pnInquiryTitleS">电话</h3>
              <input
                className="pnInquiryInput"
                type={"text"}
                placeholder={"请输入您的手机号"}
                value={inquiryPhone}
                onChange={(input) => setInquirePhone(input.target.value)}
              ></input>
            </div>
            <div className="pnInquiryInputBox">
              <h3 className="pnInquiryTitleS">称谓</h3>
              <input
                className="pnInquiryInput"
                type={"text"}
                placeholder={"请输入您的姓名"}
                value={inquiryName}
                onChange={(input) => setInquireName(input.target.value)}
              ></input>
            </div>
            <div className="pnInquiryInputBoxText">
              <div className="pnInquiryTitleS"></div>
              <textarea
                className="pnInquiryInputText"
                type={"text"}
                placeholder={"请输入您的问题"}
                value={inquiryText}
                onChange={(input) => setInquireText(input.target.value)}
              ></textarea>
              <p
                className="pcDirectTicket"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  if (!!inquiryText) {
                    setInquireText(inquiryText + "\n\n我想浏览项日葵演示系统数据");
                  } else setInquireText("我想浏览项日葵演示系统数据");
                }}
              >
                申请查看演示数据
              </p>
            </div>
            <div className="pnInquiryButtonBox">
              <button onClick={endInquiry}>退出</button>
              <button onClick={sendInquiry}>发送</button>
            </div>
          </div>
        </div>
      )}

      {showInquiry && !zhLanguage && (
        <div className="pnInquiryBackground">
          <div className="pnInquiryForm">
            <div className="pnInquiryInputBoxHeaderText">
              <p className="pnInquiryHeaderText">{inquiryHeaderText}</p>
            </div>
            <div className="pnInquiryInputBox">
              <h3 className="pnInquiryTitleS">Phone</h3>
              <input
                className="pnInquiryInput"
                type={"text"}
                placeholder={"Please input your phone number"}
                value={inquiryPhone}
                onChange={(input) => setInquirePhone(input.target.value)}
              ></input>
            </div>
            <div className="pnInquiryInputBox">
              <h3 className="pnInquiryTitleS">Name</h3>
              <input
                className="pnInquiryInput"
                type={"text"}
                placeholder={"Please input your name"}
                value={inquiryName}
                onChange={(input) => setInquireName(input.target.value)}
              ></input>
            </div>
            <div className="pnInquiryInputBoxText">
              <div className="pnInquiryTitleS">Text</div>
              <textarea
                className="pnInquiryInputText"
                placeholder={"Please describe your question in details"}
                value={inquiryText}
                onChange={(input) => setInquireText(input.target.value)}
              ></textarea>
              <p
                className="pcDirectTicket"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  if (!!inquiryText) {
                    setInquireText(inquiryText + "\n\nI would like to apply for access to sf2Pro demo data");
                  } else setInquireText("I would like to apply for access to sf2Pro demo data");
                }}
              >
                Request to view demo data
              </p>
            </div>
            <div className="pnInquiryButtonBox">
              <button onClick={endInquiry}>Quit</button>
              <button onClick={sendInquiry}>Send</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
